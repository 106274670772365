<template>
  <div>
    <content-background-cover
      class='z-0'
      :top-offset='0'
      background-color='#FFF4F8' />
    <div class='py-8'>
      <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/apsr2022-ondemand/selfie_event.jpg' class='w-2/5 rounded-md shadow-md mx-auto' />
    </div>
    <social-media-style-images :banner-image='eventPromotionalEventBannerUrl' />
  </div>
</template>

<script>
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'
import SocialMediaStyleImages from '@/components/social-media-style/SocialMediaStyleImages.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Events',
  components: {
    ContentBackgroundCover,
    SocialMediaStyleImages,
  },
  computed: {
    ...mapGetters('events', [
      'eventPromotionalEventBannerUrl',
      'showingEventName',
    ]),
  },
}
</script>
